<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="grpTable"
          title="코드그룹"
          tableId="grpTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :columns="grpGrid.columns"
          :isExcelDown="false"
          :data="grpGrid.data"
          @rowClick="getCodeMst"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <c-btn label="검색" icon="search"  @btnClicked="getCodeGroup"/>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="mstTable"
          title="코드 마스터"
          tableId="mstTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :columns="mstGrid.columns"
          :isExcelDown="false"
          :data="mstGrid.data"
          selection="multiple"
          rowKey="code"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <c-btn label="추가" icon="add" @btnClicked="addrow" v-if="editable && isSelectGrp" />
            <c-btn label="저장" icon="save" @btnClicked="saveMst" v-if="editable && isSelectGrp" />
            <c-btn label="삭제" icon="remove" @btnClicked="removeRow" v-if="editable && isSelectGrp" />
          </template>
        </c-table>
      </div>
    </div>
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">코드</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="hiddenMstCd" autofocus @keyup.enter="setMst" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="취소" @click="cancelMst" />
          <q-btn flat label="추가" @click="setMst" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'code-use-manager',
  data() {
    return {
      editable: true,
      prompt: false,
      searchParam: {
        srchDomainCd: null,
      },
      hiddenMstCd: '',
      isSelectGrp: false,
      selectedGroupCd: '',
      domainItems: [],
      domainlistUrl: '',
      grouplistUrl: '',
      mstSaveUrl: '',
      mstDeleteUrl: '',
      grpGrid: {
        columns: [
          {
            name: 'codeGrpCd',
            field: 'codeGrpCd',
            label: '그룹 코드',
            align: 'center',
            style: 'width:50%',
            sortable: true,
          },
          {
            name: 'codeGrpNm',
            field: 'codeGrpNm',
            label: '그룹명',
            style: 'width:50%',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      mstGrid: {
        columns: [
          {
            name: 'code',
            field: 'code',
            label: '코드',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            required: true,
            name: 'codeName',
            field: 'codeName',
            label: '코드명',
            align: 'left',
            style: 'width:300px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'attrVal1',
            field: 'attrVal1',
            label: '추가속성1',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'attrVal2',
            field: 'attrVal2',
            label: '추가속성2',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:70px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            required: true,
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            style: 'width:60px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
        ],
        data: [],
      },
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.grouplistUrl = selectConfig.sys.code.usegroup.list.url;
      this.mstlistUrl = selectConfig.mdm.cmd.codeMst.list.url;
      this.mstSaveUrl = transactionConfig.mdm.cmd.codeMst.save.url;
      this.mstDeleteUrl = transactionConfig.mdm.cmd.codeMst.delete.url;
      this.getCodeGroup();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        for(let c = 0; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
    },
    getCodeGroup() {
      this.rowRemoveSelect();
      this.isSelectGrp = false;
      this.mstGrid.data = [];
      this.$http.url = this.grouplistUrl;
      this.$http.param = {
        srchSystemYn: 'N'
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grpGrid.data = _result.data;
      },
      () => {
      });
    },
    getCodeMst(row) {
      this.isSelectGrp = true;
      this.selectedGroupCd = row.codeGrpCd;
      this.$http.url = this.$format(this.mstlistUrl, this.selectedGroupCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.mstGrid.data = _result.data;
      },
      () => {
      });
    },
    saveMst() {
      if (this.$comm.validTable(this.mstGrid.columns, this.mstGrid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.mstGrid.data, _item => {
              _item.companyCd = this.$store.getters.user.companyCd
            })
            this.$http.url = this.mstSaveUrl;
            this.$http.type = 'POST';
            this.$http.param = this.mstGrid.data;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getCodeMst({codeGrpCd: this.mstGrid.data[0].codeGrpCd});
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
          type: 'info', // success / info / warning / error
        });
      }
    },
    addrow() {
      this.prompt = true;
    },
    cancelMst() {
      this.hiddenMstCd = '';
      this.prompt = false;
    },
    setMst() {
      this.prompt = false;
      this.mstGrid.data.push({
        editFlag: 'C',
        companyCd: this.$store.getters.user.companyCd,
        codeGrpCd: this.selectedGroupCd,
        code: this.hiddenMstCd,
        codeName: '',
        attrVal1: '',
        attrVal2: '',
        attrVal3: '',
        attrVal4: '',
        useFlag: 'Y',
        sortOrder: this.mstGrid.data.length * 5,
      })
      this.hiddenMstCd = '';
    },
    removeRow() {
      let selectData = this.$refs['mstTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.mstGrid.data, _item => {
              _item.companyCd = this.$store.getters.user.companyCd
            })
            let cflag = 0;
            this.$_.forEach(selectData, item => {
              if (item.editFlag === 'C') {
                this.mstGrid.data = this.$_.reject(this.mstGrid.data, item);
              } else {
                cflag++;
              }
            })
            if (cflag > 0) {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message: '신규 행 추가한 항목만 삭제가능합니다.', // 신규 행 추가한 항목만 삭제가능합니다.
                type: 'warning', // success / info / warning / error
              });
            }
            this.$refs['mstTable'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
